import React from 'react';
import { Link } from 'react-router-dom';
import QrGenerator from '../projects/qrGenerator/QrGenerator';

function Hidden() {
  return (
    <div className="hidden">
      <div id="about-content">
        <h2>
          about-me:$<span className="cursor">|</span>
        </h2>
        <p>My name is wasif Naquvi and I have been programming for 4.5 years</p>
        <p>
          Some of the languages/Runtime that I am fluent in includes JavaScript,
          Node.js
        </p>
        <p>
          Ability to solve complex scenarios and problems {''}
          with a wide array of experience in developing SPAs, SSRs {''}
          REST APIs, and Higly Scalables Backend Systems.
        </p>
      </div>

      <div id="contact-content">
        <h2>
          contact-me:$<span className="cursor">|</span>
        </h2>
        <p>You can contact me at the email and phone number below</p>
        <ul>
          <li>Phone: +91 9740822770</li>
          <li>Email: wasif.corporate@gmail.com</li>
        </ul>
      </div>

      <div id="tech-content">
        <h2>
          tech-stacks:$<span className="cursor">|</span>
        </h2>
        <p>
          My Focused Tech Stacks are MERN, MEVN with a year of hands-on
          "Flask/Python"{' '}
        </p>
        <ul>
          <li>React, Next, React-Native, Vue, Angular</li>
          <li>Express.js, Meteor.js, Flask</li>
          <li>MongoDb, Postgres</li>
          <li>AWS, GCP, Azure</li>
          <li>CI/CD pipelines</li>
          <li>Docker and Kubernetes</li>
        </ul>
      </div>
      <div id="side-projects">
        <h2>
          side-projects:$<span className="cursor">|</span>
        </h2>
        <p>A minute Glimpse of my Side Projects....</p>
        <ul>
          <li>
            {' '}
            <Link to="/projects/qrgenerator" element={<QrGenerator />}>
              Qr Generator
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Hidden;

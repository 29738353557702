import React from 'react';
// import { useNavigate } from 'react-router-dom';

const ErrorfallBack = ({ error, resetErrorBoundary }) => {
  // let navigate = useNavigate();
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button
        onClick={() => {
          resetErrorBoundary();
          // navigate(`/`);
        }}
      >
        Try again
      </button>
    </div>
  );
};

export default ErrorfallBack;
